<template>
  <div class="alt-comp-wrapper">
    <v-select-alt
      :rules="required ? [allRules.required] : []"
      :label="hideLabel ? null : $attrs.label || 'Labor Type'"
      placeholder="Labor Type"
      :value="value"
      @input="emitLaborType"
      :items="enums.LABOR_TYPE_LIST"
      :hide-details="hideDetails"
      dense
      filled
      item-value="value"
      item-text="desc"
      :readonly="readonly"
      style="flex: 1"
    >
      <template v-for="item in ['selection', 'item']" #[`${item}`]="data">
        <div
          :key="item + '_laborTypeSelector'"
          class="d-flex align-center font-weight-medium"
          style="font-size: 14px"
        >
          {{ data.item.desc }}
        </div>
      </template>
    </v-select-alt>
  </div>
</template>

<script>
import enums from "../../../plugins/enums";
export default {
  data() {
    return {
      enums,
    };
  },
  props: {
    value: {
      type: Number,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitLaborType(value) {
      this.$emit("input", value);
    },
  },
};
</script>
